import { Component } from "react";
import "../../Assets/Style/Components/Blocks/_welcome.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class AboutPage extends Component {
  render() {
    return (
      <div className="AboutPage">
        <div>About Page</div>
      </div>
    );
  }
}

export default AboutPage;
