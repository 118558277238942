import { Component } from "react";
import "../../Assets/Style/Components/Blocks/_TwoStarLists.scss";
import Container from "react-bootstrap/Container";

class TwoStarLists extends Component {
  render() {
    return (
      <div className="TwoStarLists">
        <Container>
          <div className="two-lists">
            
              <div dangerouslySetInnerHTML={{ __html: this.props.data.value }}></div>
            
        
          </div>
        </Container>
      </div>
    );
  }
}

export default TwoStarLists;
