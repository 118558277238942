import { Component } from "react";
import "../../Assets/Style/Components/Blocks/_Products.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import { BiDownload } from "react-icons/bi";
import { apiService } from "../../services/ApiService";

function Products({ data, catalogue }) {
    return (
        <>
            <div className="Products">
                <Container>
                    <Row>
                        {data.map(({ title, link, text, value2 }) => {
                            return (
                                <Col lg={2} md={3} sm={4} xs={6}>
                                    <a href={link} target="_blank" className="block">
                                        <div className="block-title-container">
                                            {" "}
                                            <h5>{title}</h5>
                                        </div>
                                    </a>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </div>
            <div className="Cataloguebtn">
                <Container>
                    <div className="Cataloguebtn1">
                        <a href={apiService.imageLink + catalogue?.value2} target="_blank" className="Cataloguebtn-name">
                            Catalogue
                        </a>
                        <a href={apiService.imageLink + catalogue?.value2} download className="BsDownload">
                            <BiDownload />
                        </a>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Products;
